<template lang="pug">


.px-md-8.py-8
  v-alert.mx-4.mb-4(v-if='error', color='red', dark='', dismissible='')
    span(v-if='error' v-html='error' )
  .px-4.mb-8(flat color="transparent")
      h2 Account anlegen
      p.switch-auth-link(data-genesis-element='TEXT')
        | Sie haben schon ein Kundenkonto?
        router-link(to="/b/login" style='text-decoration: none;') Jetzt einloggen
  v-form.mt-2(
    ref='form',
    v-model='valid',
    @submit.prevent='register',
    lazy-validation=''
  )
    v-card-text
      v-row
        v-col.py-0(cols='12')
          v-text-field(
            v-model='businessName',
            outlined,

            label='Name des Unternehmens',
            type='text',
            required='',
            :rules='nameRules'
          )
        v-col.py-0(cols='12', md='6')
          v-text-field(
            v-model='firstName',
            outlined,

            label='Vorname',
            type='text',
            required='',
            :rules='nameRules'
          )
        v-col.py-0(cols='12', md='6')
          v-text-field(
            v-model='lastName',
            outlined,

            label='Nachname',
            type='text',
            required='',
            :rules='nameRules'
          )
        v-col.py-0( cols='8')
          v-text-field(
            v-model='street',
            outlined,

            label='Straße',
            type='text',
            required='',
            :rules='streetRules'
          )
        v-col.py-0( cols='4')
          v-text-field(
            v-model='streetNr',
            outlined,

            label='Hausnummer',
            type='text',
            required='',
            :rules='streetNrRules'
          )
        v-col.py-0( cols='4')
          v-text-field(
            v-model='zip',
            outlined,

            label='Postleitzahl',
            type='text',
            required='',
            :rules='zipRules'
          )
        v-col.py-0( cols='8')
          v-text-field(
            v-model='city',
            outlined,

            label='Stadt',
            type='text',
            required='',
            :rules='cityRules'
          )
        v-col.py-0(cols='12')
          v-text-field(
            v-model='email',
            outlined,

            label='E-Mail Adresse',
            type='email',
            required='',
            :rules='emailRules'
          )
        v-col.py-0(cols='12')
          v-text-field#password(
            v-model='password',
            outlined,

            :rules='passwordRules',
            label='Passwort',
            type='password',
            required=''
          )
        v-col.px-4.py-0(cols='12')
          v-checkbox(v-model='checkbox' required :rules="[v => !!v || '']")
            template(v-slot:label='')
              div
                | Ich akzeptiere die 
                a(target='_blank' :href="registerRole == 'business' ? 'https://stairship.com/agb-unternehmen' : 'https://stairship.com/agb-jobsuchende'" @click.stop) 
                  | AGB
                |  und habe die 
                a(target='_blank' href='https://stairship.com/datenschutz' @click.stop)
                  |  Datenschutzerklärung
                |  zur Kenntnis genommen.
    v-card-actions.px-4
          v-btn.text-transform-none.py-6(
            color='accent',
            rounded='',
            depressed,
            block,
            :disabled='!valid || loading',
            :loading='loading',
            type='submit'
          ) Kostenlos registrieren

          
</template>

<script>
import FormRules from '@/utilities/FormRules';
export default {
  data() {
    return {
      ...FormRules,
      valid: true,
      businessName: '',
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      street: '',
      streetNr: '',
      zip: '',
      city: '',
      inQuery: false,
      checkbox: false,
      lazy: false,
      registerRole: 'business',
    };
  },
  computed: {
    error() {
      return this.$store.getters['user/error'];
    },
    loading() {
      return this.$store.getters['user/loading'];
    },
    getRegisterRole() {
      return this.$data.registerRole;
    },
  },
  created() {
    const type = this.$route.query.type;
    if (type) this.registerRole = type;
    window.scrollTo(0, 0);
  },
  methods: {
    register() {
      if (this.$refs.form.validate()) {
        const payload = {
          password: this.password,
          newUser: {
            email: this.email.trim(),
            firstName: this.firstName,
            lastName: this.lastName,
            company: {
              name: this.businessName,
              address: {
                street: this.street,
                streetNr: this.streetNr,
                zip: this.zip,
                city: this.city,
              },
            },
            type: this.registerRole,
          },
        };
        this.$store.dispatch('user/register', payload);
      }
    },
  },
};
</script>
